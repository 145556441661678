import React, { useContext, useRef, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'Components/input'
import { AuthContext } from 'Context'
import colors from 'Components/colors.scss'
import logo from './header-logo.png'
import { Avatar, NavMenu, Sidebar } from 'Components/readonly'
import { FlexRow, FlexRowCenter, Section, Spacer } from 'Components/layout'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Dropdown } from 'Components/input/dropdown'

const Header = styled.div`
  &&& {
    min-width: 332px;
    display: flex;
    align-items: center;
    height: 66px;
    padding: 0 25px;
    background-color: ${colors.lightBackgroundColor};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);

    .logo-container {
      margin-right: 5px;
    }

    .admin-label {
      position: absolute;
      align-self: flex-start;
      right: 0;
      text-transform: capitalize;
      font-size: 10px;
    }

    .change-org {
      margin-right: 20px;
    }

    .user-area {
      text-align: right;
      line-height: 1.3em;
      margin-right: 10px;

      .p-button-text {
        padding-right: 0;
      }
    }

    @media (max-width: 992px) {
      padding: 0 10px 0 0;

      .bar-menu {
        margin-left: 10px;

        .pi-bars {
          font-size: 40px;
        }

        .p-button {
          border-radius: 2px;
        }
      }
    }

    a {
      color: ${colors.text};

      &:hover,
      &:active {
        color: ${colors.white};
      }
    }

    button {
      margin-bottom: 0;
    }
  }
`

const MobileFilterScroll = styled.div`
  overflow-y: scroll;
  padding-top: 10px;
`

const SidebarUserArea = styled.div`
  padding: 20px;
  border-bottom: 1px solid #6b6b6b;
`

const FlexRowAvatar = styled(FlexRowCenter)`
  ${Avatar} {
    margin-right: 10px;
  }

  margin-bottom: 10px;
`

const AppHeader = ({ admin, history, intl }) => {
  const {
    user,
    organisation,
    changeOrganistionAction,
    changeActiveMarketAction,
    logoutAction
  } = useContext(AuthContext)
  const [sidePanelMenuVisible, setSidePanelMenuVisible] = useState(false)
  const orgDropDownRef = useRef()
  const marketDropDownRef = useRef()

  const organisations =
    user.organisations &&
    user.organisations
      .sort((a, b) => {
        if (Number.isInteger(a.sortorder) && Number.isInteger(b.sortorder)) {
          return a.sortorder - b.sortorder
        }
        if (Number.isInteger(a.sortorder)) {
          return -1
        }
        if (Number.isInteger(b.sortorder)) {
          return 1
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      })
      .map((org) => ({
        label: org.name,
        value: org.id
      }))

  const onOrgBlur = () => {
    orgDropDownRef.current.resetFilter()
  }

  const onOrgChange = (event) => {
    changeOrganistionAction(event.value)
    setSidePanelMenuVisible(false)
  }

  const onMarketChange = (event) => {
    changeActiveMarketAction(intl.formatMessage({ id: 'lang.' + event.value })).then(() => {
      window.location.reload(false)
    })
  }

  const onLogout = () => {
    setSidePanelMenuVisible(false)
    logoutAction()
    history.push('/loggedOut')
  }

  const checkRoute = () => {
    if (user.isSuperAdmin) {
      if (
        window.location.href.includes('/admin/products') ||
        window.location.href.includes('/admin/ingredients') ||
        window.location.href.includes('/admin/filter') ||
        window.location.href.includes('/chemicalList') ||
        window.location.href.includes('/locations') ||
        window.location.href.includes('/chemicalRisk') ||
        window.location.href.includes('/hygieneThresholds') ||
        window.location.href.includes('/inventory') ||
        window.location.href.includes('/qrCodes') ||
        window.location.href.includes('/product') ||
        window.location.href.includes('/settings/import') ||
        window.location.href.includes('/settings/export') ||
        window.location.href.includes('/admin/reports')
      )
        return true
    } else {
      if (organisation.has_chemical_list) {
        if (
          window.location.href.includes('/chemicalList') ||
          window.location.href.includes('/locations') ||
          window.location.href.includes('/chemicalRisk') ||
          window.location.href.includes('/hygieneThresholds') ||
          window.location.href.includes('/inventory') ||
          window.location.href.includes('/qrCodes') ||
          window.location.href.includes('/product')
        )
          return true
      }
    }
    return false
  }

  return (
    <Header>
      <Sidebar blockScroll={true} visible={sidePanelMenuVisible} position="left"
               onHide={(e) => setSidePanelMenuVisible(false)}>
        <MobileFilterScroll>
          <SidebarUserArea className="md-hide">
            <Section>
              <FlexRowAvatar>
                <Avatar size="sm" src={user.user.avatar} name={user.user.name}/>
                <div>
                  <FormattedMessage id="welcome.welcome"/> <span className="logged-in-user-name">{user.user.name}</span>
                </div>
              </FlexRowAvatar>
              {user.handlesMultipleOrganisations && (
                <Section>
                  <Dropdown theme="dark" value={organisation.id} options={organisations} onChange={onOrgChange}
                            filter={true}/>
                </Section>
              )}

              <FlexRow>
                <Link to="/settings/">
                  <Button
                    link
                    icon="pi pi-cog"
                    label={intl.formatMessage({ id: 'common.settings' })}
                    onClick={(e) => setSidePanelMenuVisible(false)}
                  />
                </Link>
                <Spacer/>
                <Button link icon="pi pi-sign-out" label={intl.formatMessage({ id: 'auth.logout' })}
                        onClick={onLogout}/>
              </FlexRow>
            </Section>
          </SidebarUserArea>
          <NavMenu admin={user.actingAsSuperAdmin} onNav={(e) => setSidePanelMenuVisible(false)}/>
        </MobileFilterScroll>
      </Sidebar>
      <FlexRow>
        <div className="bar-menu lg-hide">
          <Button icon="pi pi-bars" onClick={(e) => setSidePanelMenuVisible(true)}/>
        </div>
      </FlexRow>
      <FlexRow className="logo-container">
        <img src={logo} alt="logo"/>
        {admin && <span className="admin-label">ADMIN</span>}
      </FlexRow>
      <Spacer/>
      {user.handlesMultipleOrganisations && (
        <div className="md-show change-org">
          <Dropdown
            ref={orgDropDownRef}
            theme="dark"
            value={organisation.id}
            options={organisations}
            onBlur={onOrgBlur}
            onChange={onOrgChange}
            filter={true}
          />
        </div>
      )}
      <div className="change-org">
        {checkRoute() && (
          <Dropdown
            ref={marketDropDownRef}
            theme="dark"
            value={intl.formatMessage({ id: 'lang.' + user.user.active_market })}
            options={
              user.isSuperAdmin
                ? organisation.organisationMarkets
                  .map((market) => intl.formatMessage({ id: 'lang.' + market }))
                  .sort()
                  .reverse()
                : user.user.userMarkets
                  .map((market) => intl.formatMessage({ id: 'lang.' + market }))
                  .sort()
                  .reverse()
            }
            onChange={onMarketChange}
          />
        )}
      </div>

      <div className="user-area md-show">
        <span className="logged-in-user-name">{user.user.name}</span>
        <br/>
        <Button link icon="pi pi-sign-out" label={intl.formatMessage({ id: 'auth.logout' })} onClick={onLogout}/>
      </div>
      <Avatar size="sm" src={user.user.avatar} name={user.user.name} className="md-show"/>
      <Link to="/settings/" className="md-show">
        <i className="pi pi-cog" style={{ fontSize: '3em' }}/>
      </Link>
    </Header>
  )
}

export default withRouter(injectIntl(AppHeader))
