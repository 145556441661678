import React, {useEffect, useRef} from 'react';
import QRCode from 'davidshimjs-qrcodejs';
import styled from 'styled-components'

const QrCodeFigure = styled.figure`
  &&& {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

/**
 * This is the main QR-code component that will generate the code
 * @param url
 * @param width
 * @returns {JSX.Element}
 * @constructor
 */
const QrCodeGenerator = ({url, width}) => {
  const qrRef = useRef(null);
  useEffect(() => {
    qrRef.current.innerHTML = '';
    new QRCode(qrRef.current, {
      text: url,
      width: 800,
      height: 800
    });
  }, [url]);

  // The width argument is only passed on print meaning "200px" is only for the presentation
  const newWidth = parseInt(width);
  const widthHeight = (isNaN(newWidth) ? '200px' : newWidth) + 'mm';

  return (
    <>
      <QrCodeFigure
        id="qr-code"
        ref={qrRef}
        style={{width: widthHeight, height: widthHeight}}
      />
    </>
  );
};

export default QrCodeGenerator;
