import React, { useRef, useState, useEffect } from 'react';
import { Growl } from 'primereact/growl'

export const GrowlErrorHandler = ({ errorSummary, errorMessage, showError }) => {
  const growl = useRef(null);
  const [messages, setMessages] = useState([]);

  /**
   * You can add error message as a component
   * @example <GrowlErrorHandler errorSummary="Error!" errorMessage="Lorem ipsum dolor" />
   */
  useEffect(() => {
    growl.current.show({
      severity: 'error',
      summary: (errorSummary ?? ""),
      detail: (errorMessage ?? ""),
      life: 3000
    });
  }, [errorSummary, errorMessage, showError]);

  /**
   * Handle message removal logic if necessary
   * @param message
   */
  const handleMessageRemove = (message) => {
    setMessages((prevMessages) => prevMessages.filter((msg) => msg !== message));
  };

  return (
    <div>
      <Growl
        className="kemrisk-growl"
        ref={growl}
        value={messages}
        onRemove={handleMessageRemove}
      />
    </div>
  );
};

