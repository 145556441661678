import styled from 'styled-components'

export const QrPageConstraint = styled.div`

  padding: 2rem 3.75rem 8.75rem 3.75rem;
  overflow: auto;
  height: 100vh;
  box-sizing: border-box;

  && {
    #logo {
      margin: 0;
    }

    .wrapper {
      max-width: 1512px;
      margin: auto;
    }

    article.wrapper {
      background-color: #323240;
    }

    .header {
      padding: 1.875rem;
    }

    .header > div {
      width: 100%;
    }

    .flex {
      display: flex;
    }

    .column-gap {
      column-gap: 10px;
    }

    .align-items-center {
      align-items: center;
    }

    .safety-sheet-button .sds-link {
      display: none;
    }

    .safety-sheet-link.inactive, .sds-link.inactive {
      opacity: 0.6;
    }

    .header h1 {
      font-size: 1.6rem;
      line-height: 1.2em;
      font-weight: bold;
      margin: 0 0 10px 0;
      padding: 0 10% 0 0;
      flex: 1 1 auto;
    }

    .header span.normal {
      font-weight: normal;
    }

    .tab-nav {
      overflow: hidden;
      border-radius: 50px;
      display: flex;
      flex: 0 0 auto;
      margin-left: auto;
    }

    .tab-nav a {
      padding: 13px 20px;
      font-size: 0.938rem;
      cursor: pointer;
      text-decoration: none;
      background-color: #1D2134;
    }

    .tab-nav a.active {
      background-color: #3BB377;
    }

    .p-overlaypanel p {
      margin: 0;
    }

    .p-overlaypanel::before, .p-overlaypanel::after {
      display: none;
    }
  }

  @media (max-width: 920px) {
    padding: 0 0 6rem 0;

    && {
      .header h1 {
        font-size: 1.5rem;
        text-align: center;
        padding: 0;
        margin-bottom: 1rem !important;
      }

      .header.flex {
        flex-direction: column;
      }

      .search-container {
        width: 100%;
      }

      .tab-nav {
        margin: 1rem 0 0 0;
      }

      .tab-nav a {
        font-size: 0.875rem;
        padding: 10px 15px;
      }

      .safety-sheet-button > .flex {
        width: 100%;
        justify-content: center;
      }

      .safety-sheet-button .sds-link {
        display: inline-block;
      }

      .sds-button, .safety-sheet-button .p-column-title {
        display: none !important;
      }
    }

    @media (max-width: 520px) {
      && {
        .p-column-title {
          width: auto !important;
          min-width: 120px !important;
        }
      }
    }
  }
`;
