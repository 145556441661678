import colors from 'Components/colors.scss'
import styled from 'styled-components'

export const QrPageConstraint = styled.div`
  && {
    .button-container {
      width: 100%;
      display: flex;
      justify-content: end;
      column-gap: 5%;
    }
  }

  @media (max-width: 640px) {
    && {
      .button-container {
        column-gap: 15px;
        justify-content: center;
      }

      .buttons {
        min-width: inherit;
      }

      .buttons .p-column-title {
        display: none !important;
      }
    }
  }
`;

export const IconError = styled.span`
  &&& {
    font-size: 30px;
    color: ${colors.red};
  }
`

export const Wrapper = styled.div`
  && {
    max-width: 760px;
    padding: 0;
  }

  width: 100%;
  height: 100%;

  .filter-holder label:first-child {
    max-width: 480px;
    margin-bottom: 10px;
  }

  .filter-holder > div > div {
    height: 260px;
    overflow: auto;
    background-color: #363643;
    padding: 5px;
  }

  .filter-holder .pi-search {
    top: 12px;
  }
`
