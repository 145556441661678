import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { Dropdown } from '.'
import useGetData from 'Hooks/useGetData.js'

export const MarketLanguageDropdown = (props) => {
  const intl = useIntl()
  const [isLoading, langList] = useGetData(
    props.productExists ? '/rapi/sds/marketLangList' : '/rapi/sds/newProductMarketLangList',
    [],
    [intl.locale]
  )
  const [options, setOptions] = useState([])
  const defaultLabel = props.defaultLabel ? props.defaultLabel : intl.formatMessage({id: 'sds.select_language'})

  /**
   * This will exclude options if value key is in prop excludeOptions
   * @param langList
   * @returns {*|*[]}
   */
  const filterOptions = (langList) => {
    if (typeof props.excludeOptions === "object") {
      const opt = [];
      langList.forEach((row) => {
        if (typeof props.excludeOptions?.[row.value] === "object") {
          opt.push(row);
        }
      });
      return opt;
    }
    return langList;
  };

  useEffect(() => {
    setOptions([{label: defaultLabel, value: null}, ...filterOptions(langList)])
  }, [langList, intl, defaultLabel])

  return <Dropdown style={{maxWidth: '200px'}} options={options} disabled={isLoading}
                   appendTo={document.body} {...props} />
};
