import * as React from 'react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import axios from 'axios'
import styled from 'styled-components'
import { ProgressSpinner } from 'primereact/progressspinner'

import { AuthContext, GrowlContext } from 'Context'
import { Checkbox, Switch } from 'Components/input'
import { Button, Panel, SdsDetailTable, SdsDownloadButton, SdsSidebar, TextWithCopyButton } from 'Components/readonly'
import useGetData from 'Hooks/useGetData'
import SdsArchiveDialog from 'Components/combined/sdsArchiveDialog'
import useModalController from 'Hooks/useModalController'
import CommentInput from './components/commentInput'
import { HasCapability, HasService, useCapability } from 'Components/capabilities'
import ExternalSdsCommentInput from './components/externalSdsCommentInput'
import { ButtonBar, Section } from 'Components/layout'
import LabelingView from './components/labelingView'

const Wrapper = styled.div`
  ${Panel} {
    margin-bottom: 20px;
  }

  h2 {
    padding: 20px 0 10px 0;
  }
`

const CommentPanel = styled(Panel)`
  padding: 10px;
  border-radius: 10px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const CommentHeader = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
`

const SdsDetailView = injectIntl(
  ({
     intl,
     sds,
     kind,
     publicBody,
     close,
     onSdsUpdated,
     onSdsArchived,
     archiveDialogOpen,
     setArchiveDialogOpen,
     deleteLabelingModalController,
     uploadLabelingModalController
   }) => {
    const { organisation } = useContext(AuthContext)
    const { displayError, displaySuccess } = useContext(GrowlContext)

    const [isLoading, sdsDetail, setSdsDetail] = useGetData(sds ? '/rapi/sds/detail/' + kind + '/' + sds.id : null, {})

    const [linkVersion, setLinkVersion] = useState(false)
    const [approveSpin, setApproveSpin] = useState(false)
    const [approveIsPublic, setApproveIsPublic] = useState(null)

    const hasManageSdsCapability = useCapability('MANAGE_SDS')

    // Update weblinkRoute display
    const link = useMemo(() => {
      if (sds && sdsDetail) {
        return kind === 'external' ? sdsDetail.weblinkExternal : linkVersion ? sdsDetail.weblinkRouteSpecific : sdsDetail.weblinkRouteLatest
      }
      return ''
    }, [sds, sdsDetail, linkVersion, kind])

    useEffect(() => {
      // Default to same isPublic as parent
      setApproveIsPublic(sdsDetail.parentIsPublic)
    }, [sdsDetail])

    const approve = async () => {
      setApproveSpin(true)
      try {
        const result = await axios.post('/rapi/sds/approve', {
          id: sds.id,
          isPublic: approveIsPublic
        })
        onSdsUpdated(result.data)
        displaySuccess(intl.formatMessage({ id: 'sds.sds-approved' }))
      } catch (error) {
        displayError(error)
      }
      setApproveSpin(false)
    }

    const onArchiveDone = () => {
      onSdsArchived(sds)
      setArchiveDialogOpen(false)
      displaySuccess(kind === 'external' ? intl.formatMessage({ id: 'sds.sds-deleted' }) : intl.formatMessage({ id: 'sds.sds-archived' }))
      close()
    }

    const onCommentByCustomerSubmitted = (commentByCustomer) => {
      setSdsDetail({ ...sdsDetail, commentsByCustomer: [...sdsDetail.commentsByCustomer, commentByCustomer] })
    }

    return (
      <Wrapper id="SdsDetailView">
        <SdsArchiveDialog kind={kind} sds={sds} isOpen={archiveDialogOpen} setIsOpen={setArchiveDialogOpen}
                          onArchiveDone={onArchiveDone}/>
        {isLoading && <ProgressSpinner/>}
        {!isLoading && (
          <>
            <Panel lighter>
              <h2>
                <FormattedMessage id="sds.information"/>
              </h2>
              <SdsDetailTable sds={sds}/>
              <ButtonBar>
                {onSdsArchived && sds.status !== 'notDone' && (
                  <>
                    <SdsDownloadButton primary kind={kind} sds={sds}/>
                    <HasCapability capability="MANAGE_SDS">
                      <Button
                        label={
                          kind === 'external' ? intl.formatMessage({ id: 'common.delete' }) : intl.formatMessage({ id: 'common.archive' })
                        }
                        icon="pi pi-folder"
                        onClick={() => setArchiveDialogOpen(true)}
                      />
                    </HasCapability>
                  </>
                )}
              </ButtonBar>

              {sds.status === 'notDone' && (
                <>
                  <SdsDownloadButton kind={kind} sds={sds}/>
                  <HasCapability capability="APPROVE_SDS">
                    {approveIsPublic != null && (
                      <>
                        <h3>
                          {sdsDetail.parentIsPublic ? (
                            <FormattedMessage id="sds.parent-is-public"/>
                          ) : (
                            <FormattedMessage id="sds.parent-is-not-public"/>
                          )}
                        </h3>
                        <div className="marginBottom">
                          <FormattedMessage id="sds.approve-set-is-public"/>{' '}
                          <Switch
                            value={approveIsPublic}
                            onLabel={intl.formatMessage({ id: 'common.yes' })}
                            offLabel={intl.formatMessage({ id: 'common.no' })}
                            disabled={!hasManageSdsCapability}
                            onChange={(e) => setApproveIsPublic(e.value)}
                          />
                        </div>
                        <div className="marginBottom">
                          <FormattedMessage id="sds.weblink-updates-on-approve"/>
                        </div>
                      </>
                    )}
                    <div>
                      <Button
                        accent
                        label={intl.formatMessage({ id: 'sds.approve-sds' })}
                        icon="pi pi-check"
                        isLoading={approveSpin}
                        onClick={approve}
                      />
                    </div>
                  </HasCapability>
                </>
              )}
            </Panel>

            {kind === 'external' && (
              <Panel lighter>
                <h2>
                  <FormattedMessage id="sds.information"/>
                </h2>
                <ExternalSdsCommentInput sds={sds} onSdsUpdated={onSdsUpdated}/>
              </Panel>
            )}

            {kind !== 'external' && (
              <Panel lighter>
                <h2>
                  <FormattedMessage id="common.comments"/>
                </h2>
                <Section>
                  {sdsDetail.commentsForCustomer &&
                    sdsDetail.commentsForCustomer.length === 0 &&
                    sdsDetail.commentsByCustomer &&
                    sdsDetail.commentsByCustomer.length === 0 && <FormattedMessage id="sds.no-comment"/>}

                  {sdsDetail.commentsForCustomer &&
                    sdsDetail.commentsForCustomer.map((comment) => (
                      <CommentPanel key={comment.id}>
                        <CommentHeader>
                          <FormattedMessage id="sds.kemrisk-comment"/>
                          <br/>
                          {comment.created_at}
                        </CommentHeader>
                        <div style={{ whiteSpace: 'pre-wrap' }}>{comment.comment}</div>
                      </CommentPanel>
                    ))}

                  {sdsDetail.commentsByCustomer &&
                    sdsDetail.commentsByCustomer.map((comment) => (
                      <CommentPanel key={comment.id}>
                        <CommentHeader>
                          {comment.user ? comment.user.name : intl.formatMessage({ id: 'sds.a-user' })}{' '}
                          <FormattedMessage id="sds.sent-comment"/>
                          <br/>
                          {comment.created_at}
                        </CommentHeader>
                        <div style={{ whiteSpace: 'pre-wrap' }}>{comment.comment}</div>
                      </CommentPanel>
                    ))}
                </Section>
                {sds.status === 'notDone' && (
                  <HasCapability capability="APPROVE_SDS">
                    <CommentInput sdsId={sds.id} onCommentSubmitted={onCommentByCustomerSubmitted}/>
                  </HasCapability>
                )}
              </Panel>
            )}

            {sds.status !== 'notDone' && organisation.weblink > 0 && publicBody && (
              <Panel lighter>
                <h2>
                  <FormattedMessage id="sds.is-sds-public"/>
                </h2>
                <Section>{publicBody(sds)}</Section>
                <p>
                  <FormattedMessage id="sds.sds-public-text"/>
                </p>
                <HasService service="direct_link">
                  <div className={!sds.isPublic ? 'disabled' : ''}>
                    <h2>
                      <FormattedMessage id="linking.web-linking"/>
                    </h2>
                    <TextWithCopyButton className="marginBottom" disabled={!sds.isPublic} value={link}/>
                    {kind !== 'external' && (
                      <Checkbox
                        label={intl.formatMessage({ id: 'linking.create-link' })}
                        value={linkVersion}
                        onChange={(e) => {
                          setLinkVersion(e.checked)
                        }}
                        disabled={!sds.isPublic}
                      />
                    )}
                  </div>
                </HasService>
              </Panel>
            )}

            {kind === 'internal' && (
              <>
                <Panel lighter>
                  <h2>
                    <FormattedMessage id="labeling.product-labeling-substrate"/>
                  </h2>
                  <LabelingView
                    sds={sds}
                    onSdsUpdated={onSdsUpdated}
                    deleteLabelingModalController={deleteLabelingModalController}
                    uploadLabelingModalController={uploadLabelingModalController}
                  />
                </Panel>
              </>
            )}
          </>
        )}
      </Wrapper>
    )
  }
)

const SdsDetail = ({ ...props }) => {
  const intl = useIntl()
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)
  const [uploadLabelingModalController] = useModalController({ title: intl.formatMessage({ id: 'labeling.upload-new' }) })
  const [deleteLabelingModalController] = useModalController({ title: intl.formatMessage({ id: 'common.delete' }) })

  const isAnyModalOpen = archiveDialogOpen || uploadLabelingModalController.isOpen || deleteLabelingModalController.isOpen

  return (
    <SdsSidebar
      id="SdsSidebar"
      Component={SdsDetailView}
      isAnyModalOpen={isAnyModalOpen}
      archiveDialogOpen={archiveDialogOpen}
      setArchiveDialogOpen={setArchiveDialogOpen}
      deleteLabelingModalController={deleteLabelingModalController}
      uploadLabelingModalController={uploadLabelingModalController}
      {...props}
    />
  )
}

export default SdsDetail
