import React, {useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import ReactDOM from 'react-dom';
import QrCodeGenerator from './QrCodeGenerator';
import QrCodesPrintablePage from '../QrCodesPrintablePage';
import { Button } from "Components/input";
import { Field, Form } from 'Components/form'
import styled from 'styled-components';
import { InlineTextInput } from "../../settings/components/inlineTextInput";
import ValidationFailedMessage from 'Components/form/validationFailedMessage'

const Aside = styled.aside`
  &&& {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    gap: 0 10px;
  }

  #button-print-cancel {
    margin-left: auto;
  }
`

const Container = styled.div`
  &&  {
    max-width: 420px;
    margin: 20px auto 40px;
    text-align: center;

    #qr-code {
      margin-bottom: 20px;
    }

    h1, h2 {
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0;
      margin-bottom: 20px;
      display: flex;
      text-align: left;
      column-gap: 10px;
      justify-content: center;
    }
    h1 span.normal, h2 span.normal {
      font-weight: normal;
    }

    .message {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 14px;
      font-style: italic;
    }

    .message.error {
      margin-left: -20px;
      font-style: normal;
    }

    .message.error span {
      margin-top: -2px;
    }
  }

  &&, #field-container  {
    align-items: center;
    gap: 0 20px;
  }

  #field-container {
    gap: 0 10px;
    display: flex;
    justify-content: center;

    label {
      margin-bottom: 10px;
    }

    span {
      padding-top: 9px;
    }

    input {
      text-align: center;
    }
  }

  .qr-width {
    width: 80px;
  }
`

/**
 * Will get unique saved size on every specific QR, set in the code size field
 * @param UUID
 * @param defaultValue
 * @returns {*}
 */
const getQrSize = function(UUID, defaultValue) {
  if(localStorage.getItem("qrCodeWidth")) {
    const obj = JSON.parse(localStorage.getItem("qrCodeWidth"));
    return (obj[UUID] ?? defaultValue);
  }
  return defaultValue;
}

const ReadQrModal = ({modalController, modalData}) => {
  const intl = useIntl();
  const modalDataId = modalData?.id ?? 0;
  const [inputWidthVal, setInputWidthVal] = useState(getQrSize(modalDataId, "20"));
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  const qrUrl = (publicUrl.origin ?? publicUrl.href) + '#/user-area/qr/' + modalDataId;
  const [error, setError] = useState(false)

  /**
   * Store/update QR code from the active select choices
   * @returns {Promise<void>}
   */
  const handlePrint = () => {
    const title = modalData.location_id ? modalData.location_name : modalData.productSearch;
    const newWindow = window.open('', '', 'width=800,height=600');
    newWindow.document.write('<div id="printable"></div>');
    newWindow.document.close();
    ReactDOM.render(<QrCodesPrintablePage
        title={title}
        url={qrUrl}
        uuid={modalDataId}
        window={newWindow}
        buttonTitle={intl.formatMessage({id: 'common.print'})} />,
      newWindow.document.getElementById('printable'));
  };

  /**
   * Change QR width
   * @param event
   */
  const onChange = (event) => {
    const int = parseInt(event.target.value);
    if(!isNaN(int) && int >= 1 && int <= 200) {
      setInputWidthVal(int);
      setError("");
    } else {
      setError(intl.formatMessage({id: 'qr-codes.qr-code-size-error'}));
    }
  };

  /**
   * Use the effect change to "inputWidthVal" to make sure
   * that local storage item qrCodeWidth is set!
   */
  useEffect(() => {
    let obj = {};
    if(localStorage.getItem("qrCodeWidth")) {
      try {
        obj = JSON.parse(localStorage.getItem("qrCodeWidth"));
      } catch (error) {
        console.warn("Could not convert data to json, clearing QR-code width local storage.");
      }
    }
    obj[modalDataId] = inputWidthVal;
    localStorage.setItem("qrCodeWidth", JSON.stringify(obj));
  }, [inputWidthVal, modalDataId]);

  return (
    <>
      <Form>
        {error && (
          <ValidationFailedMessage message={error} />
        )}
        <Container>
          <h2>
            <span>{modalData.location_id ? intl.formatMessage({id: 'common.location'}) + ": " : intl.formatMessage({id: 'chemicals.product'}) + ": "}</span>
            <span className="normal">{modalData.location_id ? modalData.location_name : modalData.productSearch}</span>
          </h2>
          <QrCodeGenerator url={qrUrl}/>
          <div>
            <label>{intl.formatMessage({id: 'qr-codes.qr-size-label'})}</label>
            <div id="field-container">
              <Field
                id="qr-width"
                type="number"
                className="qr-width"
                Component={InlineTextInput}
                maxLength="4"
                required={true}
                initialValue={inputWidthVal}
                onChange={onChange}
              />
              <span>mm</span>
            </div>
          </div>
          <div className="message">{intl.formatMessage({id: 'qr-codes.recommended-size'})}</div>
        </Container>
        <Aside>
          <Button id="button-print-cancel" label={intl.formatMessage({id: 'common.cancel'})} onClick={() => modalController.close()}/>
          <Button id="button-print" primary label={intl.formatMessage({id: 'common.print'})} onClick={handlePrint}/>
        </Aside>
      </Form>
    </>
  );
};

export default ReadQrModal;
