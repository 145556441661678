import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import useGetData from 'Hooks/useGetData';
import { format, parse } from 'date-fns'
import { globalSearch } from 'Utils/search'
import { useHistory, useParams } from 'react-router-dom';
import { TableContainer } from 'Components/layout';
import { Button, DataTable } from 'Components/readonly';
import { Column } from 'Components/readonly';
import { QrPageConstraint } from './components/QrPageConstraint';
import { SearchInput } from "Components/input";
import { GrowlErrorHandler } from './components/GrowlErrorHandler';

/**
 * Get HTTP requests
 * @param args
 * @returns {{baseUrl: string, hashWithoutQuery: string, fullUrl: string, queryStr: {}}}
 */
function getHttp(args) {
  const fullUrl = window.location.href; // Get the full URL
  const [baseUrl, hashWithQuery] = fullUrl.split('#');
  const [hashWithoutQuery, queryString] = hashWithQuery.split('?');
  let queryStr = Object.assign((args ?? {}), {}), rawQueryStr = {};
  if (hashWithQuery && hashWithQuery.includes('?')) {
    const searchParams = new URLSearchParams(queryString);
    queryStr = Object.fromEntries(searchParams.entries());
    rawQueryStr = queryStr;
  }
  return {queryStr, rawQueryStr, baseUrl, fullUrl, hashWithoutQuery};
}

/**
 * This will return the correct SDS language from lang key
 * @param key
 * @param defaultValue
 * @returns {*|string}
 */
const sdsLanguages = (key, defaultValue) => {
  const sdsLangObj = {
    sv: "SWE",
    en: "ENG",
    dk: "DAN",
    no: "NOR",
  }
  return (typeof sdsLangObj?.[key] === "string") ? sdsLangObj?.[key] : (defaultValue ?? "ENG");
}

const QrCodesPage = () => {
  const intl = useIntl()
  const {uuid} = useParams()
  const http = getHttp({language: "sv"});
  const languageInQuery = http?.rawQueryStr?.language;
  const host = `${http.baseUrl}#${http.hashWithoutQuery}`;
  const currentURL = '/user-area/qr/' + uuid;
  const [isLoading, qrCodeList] = useGetData(currentURL, [], [])
  const [lang, setLang] = useState(http.queryStr.language ?? "sv");
  const [sdsLang, setSdsLang] = useState(sdsLanguages(http.queryStr.language, "SWE"));
  const [initLang] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(0);
  const [inputFilter, setInputFilter] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const history = useHistory();

  /**
   * This will filter list, so it's readable by the table component
   * @param list
   * @param search
   * @returns {*[]}
   */
  const getFilteredList = useCallback((list, search) => {
    let data = [];
    if (list.products) {
      list.products.forEach((row) => {
        row.chemical.language_variants = row.chemical.language_variants || {};
        const item = row?.chemical?.language_variants?.[lang];

        if (item) {
          const createdAt = format(parse(row.chemical.created_at), 'YYYY-MM-DD');
          data.push({
            id: row.chemical.id,
            article_number: item.article_number,
            name: item.name,
            language: item.language,
            supplier: item.supplier,
            all_risk_assessments: (row?.chemical?.all_risk_assessments ?? []),
            sds_active: row.chemical.sds_active,
            created_at: createdAt,
          });
        }
      });

      // Search filter the list (MOVE LATER)
      if (typeof search === 'string' && search.length > 0) {
        data = globalSearch([...data], search, ['article_number', 'name', 'supplier']);
      }
    }
    return data;
  }, [lang]);

  /**
   * Validate if SDS file has language support
   * @param row
   * @returns {int|boolean}
   */
  const sdsHasLang = (row) => {
    return (row.sds_active?.[sdsLang]?.id) ? row.sds_active?.[sdsLang]?.id : false;
  }

  /**
   * This will add SDS button to table
   * @param row
   * @returns {JSX.Element|string}
   */
  const sdsButton = (row) => {
    if (row.sds_active) {
      let hasLang = true;
      let sdsId = sdsHasLang(row);
      if (!sdsId) {
        const firstKey = Object.keys(row.sds_active)[0];
        sdsId = row.sds_active[firstKey].id;
        hasLang = false;
      }
      return <a className={!hasLang ? "sds-link inactive" : "sds-link"} href={`${currentURL}/${sdsId}`} target="_blank" rel="noopener noreferrer">
        <Button
          onClick={(event) => {
            if (!hasLang) {
              event.preventDefault();
              setShowErrorMsg(showErrorMsg + 1);
            }
          }}
          style={{margin: 0}}
          label={intl.formatMessage({id: 'common.sds_ucfirst'})}
        />
      </a>
    }
    return '';
  };

  /**
   * Retrieve the SDS update date if available; otherwise, default to the product creation date
   * @param row
   * @returns {string}
   */
  const sdsUpdateDate = (row) => {
    if (row.sds_active && sdsHasLang(row)) {
      const obj = row.sds_active[sdsLang];
      obj.sds_date = (obj?.sds_date ?? false) ;
      if (obj?.sds_date) {
        return format(parse(obj.sds_date), 'YYYY-MM-DD');
      }
    }
    return row.created_at;
  };

  /**
   * This will add safety sheet buttons to table
   * @param row
   * @returns {JSX.Element}
   */
  const safetySheetButtons = (row) => {
    const hasNotLang = (!sdsHasLang(row) || row.language !== lang);
    return (
      <div className="flex column-gap">
        <a className={hasNotLang ? "safety-sheet-link inactive" : "safety-sheet-link"} href={`${currentURL}/${lang}/safety-sheet/chemical/${row.id}`} target="_blank"
           rel="noopener noreferrer">
          <Button
            primary
            onClick={(event) => {
              if (hasNotLang) {
                event.preventDefault();
                setShowErrorMsg(showErrorMsg + 1);
              }
            }}
            style={{margin: 0}}
            label={intl.formatMessage({id: 'safetysheet.safety_sheet'})}
          />
        </a>
        {sdsButton(row)}
      </div>
    );
  };

  /**
   * Build the language navigation
   * @returns {*[]}
   */
  const LanguageNav = () => {
    let buttons = [];
    if (!isLoading && qrCodeList?.types?.language_variants) {
      Object.entries(qrCodeList.types.language_variants).forEach(([key]) => {
        buttons.push(
          <a key={"lang-btn-" + key} className={key === lang ? "active" : ""}
             href={host + `?language=${key}`}
             onClick={(event) => changeLanguage(event, key)}>
            {intl.formatMessage({id: 'lang.'+key })}
          </a>
        );
      });
    }
    return buttons;
  };

  /**
   * Change language
   * @param event
   * @param key
   */
  const changeLanguage = (event, key) => {
    event.preventDefault();
    setLang(key);
    setSdsLang(sdsLanguages(key));
    history.push(currentURL + "?language=" + key, {replace: false});
  };

  /**
   * Update list on changes
   */
  useEffect(() => {
    setFilteredList(getFilteredList(qrCodeList, inputFilter));
  }, [qrCodeList, inputFilter, getFilteredList]);

  /**
   * This will automatically choose expected translation language
   * from available client markets and languages
   */
  useEffect(() => {
    if (!isLoading && !languageInQuery && qrCodeList.lang !== "sv") {
      setLang(qrCodeList.lang);
      history.push(currentURL + "?language=" + qrCodeList.lang, {replace: false});
    }
  }, [isLoading, initLang, currentURL, history, languageInQuery, qrCodeList.lang]);

  return (
    <>
      <QrPageConstraint>
        {(showErrorMsg > 0) && (
          <GrowlErrorHandler showError={showErrorMsg} errorSummary={intl.formatMessage({id: 'common.not_available_in_lang'})}/>
        )}
        <header className="header">
          <div className="wrapper">
            <figure id="logo">
              <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="168" height="44" viewBox="0 0 167.8 43.5">
                <path fill="#ec7d23"
                      d="M7.2 25.7 0 18.4 18.4 0 35 16.5l-2.4 2.4L18.4 4.7 4.7 18.4l4.9 4.9-2.4 2.4z"></path>
                <path fill="#FFF"
                      d="M35.4 29.4h19.1v3.3H35.4zm0 10.8h19.1v3.3H35.4zm0-21.3h19.1v3.3H35.4zm-23.5 0h3.3v24.6h-3.3zm8.9 12.9c-.3-.3-.3-.9 0-1.2l11.7-11.7h-4.7l-9.3 9.3c-1.6 1.6-1.6 4.3 0 5.9l9.4 9.4h4.7L20.8 31.8zM84 19.2c-.5-.2-1.7-.6-3.1.6L73 26.5c-.4.4-1.3.4-1.7 0l-7.9-6.7c-1.4-1.2-2.7-.9-3.1-.6-.5.2-1.5.9-1.5 2.8v21.6H62V23.1l7.1 6c1.7 1.4 4.3 1.4 6 0l7.1-6v20.5h3.3V22c0-1.9-1-2.6-1.5-2.8zm19 15.3c-.2-.2-.2-.4-.2-.5 0-.1 0-.3.2-.5l3.5-3.2c1.3-1.3 2.4-3.6 2.4-5.4v-1.7c0-2.3-1.9-4.2-4.2-4.2H88.3v3.3h16.3c.4 0 .8.4.8.8v1.7c0 .9-.6 2.4-1.3 3l-3.5 3.2c-.8.8-1.3 1.8-1.3 2.9 0 1.1.4 2.2 1.2 2.9l6.9 6.7h4.8l-9.2-9zm12.1-15.6h3.3v24.6h-3.3zm26.6 23.5c.8-.8 1.2-1.9 1.2-3v-2.9c0-2.1-1.5-4.2-3.5-4.9L127 27.3c-.6-.2-1.2-1.1-1.2-1.7v-2.4c0-.5.4-.9.9-.9H143V19h-16.3c-2.3 0-4.2 1.9-4.2 4.2v2.5c0 2.1 1.5 4.2 3.4 4.9l12.4 4.4c.6.2 1.2 1.1 1.2 1.8v2.9c0 .3-.2.5-.2.6-.1.1-.3.2-.6.2h-16.3v3.3h17.1c.9-.4 1.6-.8 2.2-1.4zm5.4-23.5h3.3v24.6h-3.3zm8.9 12.9c-.3-.3-.3-.9 0-1.2l11.7-11.7H163l-9.3 9.3c-1.6 1.6-1.6 4.3 0 5.9l9.4 9.4h4.7L156 31.8z"></path>
              </svg>
            </figure>
          </div>
        </header>
        <article className="wrapper">
          <header className="header flex align-items-center">
            <div>
              <h1>
                {qrCodeList.header && (
                  <>
                    <span>{qrCodeList && qrCodeList.header?.location_name ? intl.formatMessage({id: 'common.location'}) + ": " : intl.formatMessage({id: 'chemicals.product'}) + ": "}</span>
                    <span
                      className="normal">{qrCodeList?.header?.location_name ?? (qrCodeList?.products?.[0]?.chemical?.language_variants?.[lang]?.name ?? "")}</span>
                  </>
                )}
              </h1>
              {qrCodeList?.header?.location_name && (
                <SearchInput className="search-field" value={inputFilter} onChange={(event) => {
                  setInputFilter(event.target.value)
                }}/>
              )}
            </div>
            <nav className="languages tab-nav flex">
              <LanguageNav/>
            </nav>
          </header>
          <TableContainer className="fix-ie-table-width flextable">
            <DataTable
              responsive={true}
              className="clickable-rows"
              value={filteredList}
              loading={isLoading}
              rows={8}
              paginator={Boolean(qrCodeList?.header?.location_name)}
            >
              <Column style={{width: '10%'}} key="article_number" field="article_number"
                      header={intl.formatMessage({id: 'chemicals.article_id'})}
                      sortable={Boolean(qrCodeList?.header?.location_name)}/>
              <Column style={{width: '35%'}} key="name" field="name"
                      header={intl.formatMessage({id: 'chemicals.product'})}
                      sortable={Boolean(qrCodeList?.header?.location_name)}/>
              <Column style={{width: '35%'}} key="supplier" field="supplier"
                      header={intl.formatMessage({id: 'common.supplier'})}
                      sortable={Boolean(qrCodeList?.header?.location_name)}/>
              <Column style={{width: '15%'}}
                      key="created_at"
                      field="created_at"
                      header={intl.formatMessage({id: 'sds.issue-date'})}
                      sortable={Boolean(qrCodeList?.header?.location_name)}
                      body={sdsUpdateDate}
              />
              <Column
                style={{width: '200px', textAlign: "center"}}
                className="safety-sheet-button"
                key="safety_sheet"
                field="safety_sheet"
                body={safetySheetButtons}
                sortable={false}
              />
              <Column
                style={{width: '200px', textAlign: "center"}}
                className="sds-button"
                key="sds"
                field="sds"
                body={sdsButton}
                sortable={false}
              />
            </DataTable>
          </TableContainer>
        </article>
      </QrPageConstraint>
    </>
  );
};

export default QrCodesPage;
